import { SID } from '@securitize/domain';

export default {
  securitizeId: {
    entityType: {
      [SID.InvestorEntityTypes.REVOCABLE_TRUST]: 'Revocable Trust',
      [SID.InvestorEntityTypes.IRREVOCABLE_TRUST]: 'Irrevocable Trust',
      [SID.InvestorEntityTypes.LIMITED_GENERAL_PARTNERSHIP]: 'Limited Partnership/General Partnership',
      [SID.InvestorEntityTypes.LLC]: 'LLC',
      [SID.InvestorEntityTypes.CORPORATION]: 'Corporation',
      [SID.InvestorEntityTypes.FUND]: 'Fund',
      [SID.InvestorEntityTypes.JOINT_ACCOUNT]: 'Joint Account',
      [SID.InvestorEntityTypes.FOR_PROFIT_FOUNDATION]: 'For-Profit Foundation',
      [SID.InvestorEntityTypes.NON_PROFIT_FOUNDATION]: 'Non-Profit Foundation',
      [SID.InvestorEntityTypes.OTHER]: 'Other',
    },
    kyc: {
      option: {
        status: {
          none: 'None',
          processing: 'Processing',
          updateRequired: 'Updates Required',
          verifiedDocumentsExpired: 'Verified[documents-expired]',
          verifiedExpired: 'Verified[expired]',
          verified: 'Verified',
          enhanceRequested: 'Enhance Requested',
          enhanceVerified: 'Enhance Verified',
          manualReview: 'Manual Review',
          rejected: 'Rejected',
          rejectedBlocked: 'Rejected[blocked]',
          expired: 'Expired',
        },
      },
      label: {
        runRegulationCheck: 'Run {0} Check',
        rerunRegulationCheck: 'Rerun {0} Check',
        viewExternalReport: 'View external report',
      },
      errors: {
        'resultcode-coppa-alert': 'COPPA Alert',
        'resultcode-address-does-not-match': 'Address does not match',
        'resultcode-street-name-does-not-match': 'Street Name Does Not Match',
        'resultcode-street-number-does-not-match': 'Street Number Does Not Match',
        'resultcode-input-address-is-po-box': 'Input Address is a PO Box',
        'resultcode-zip-does-not-match': 'ZIP Code Does Not Match',
        'resultcode-yob-does-not-match': 'YOB Does Not Match',
        'resultcode-mob-does-not-match': 'MOB Does Not Match',
        'resultcode-newer-record-found': 'Newer Record Found',
        'resultcode-ssn-does-not-match': 'SSN Does Not Match',
        'resultcode-ssn-within-one-digit': 'SSN Does Not Match, within tolerance',
        'resultcode-state-does-not-match': 'State Does Not Match',
        'resultcode-single-address-in-file': 'Single Address in File',
        'resultcode-thin-file': 'Thin File',
        'resultcode-no-dob-available': 'DOB Not Available',
        'resultcode-ssn-not-available': 'SSN Not Available',
        'resultcode-subject-deceased': 'Subject Deceased',
        'resultcode-ssn-issued-prior-to-dob': 'SSN Issued Prior to DOB',
        'resultcode-ssn-not-valid': 'SSN Invalid',
        'resultcode-warm-address-alert': 'Warm Address',
      },
    },
    title: 'Investors',
    button: {
      table: {
        export: 'Export List',
        edit: 'Edit labels',
      },
      investor: {
        dropdown: 'Add',
        add: 'Add Investor',
        omnibus: 'Add Omnibus Wallet Owner',
        import: 'Import investors',
        delete: 'Delete investor',
      },
    },
    filterTypes: {
      'issuer-list': 'Issuer',
      countries: 'Country',
      states: 'State',
      verificationErrors: 'KYC Error',
      verificationStatus: 'KYC Status',
      'investor-type': 'Inv. Type',
      assignedAttorneyOperatorId: 'Assignee',
    },
    message: {
      deleteConfirmation: 'Are you sure you want to delete "{0}"?',
    },
    translations: {
      titles: {
        translations: 'Translations',
        csv: 'CSV Import',
      },
      editModal: {
        title: 'Edit translations',
        EN: 'English',
        ES: 'Spanish',
        save: 'Save',
      },
      importTab: {
        continue: 'Continue',
        overrideAll: 'Override all edited translations',
        downloadTranslations: 'Download existing CSV translations',
        clearData: 'Clear data',
      },
    },
    fileUpload: {
      'entity-type-required': '* Please select an entity type',
    },
  },
  investor: 'investor',
};
