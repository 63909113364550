import axios from '~/utilities/axios';

const BASE_PATH = '/api/v2';

export const BULK_DS_PROTOCOL_TRANSACTIONS_FEATURE_FLAG = 'bc-bulk-ds-protocol-transactions-enabled';

export default class IssuerClientConfigurations {
    static async getClientConfigurations(issuerId, featureFlags) {
        try {
            const res = await axios.get(`${BASE_PATH}/issuers/${issuerId}/configurations/client-configs`);
            const clientConfigs = res.data;

            if (clientConfigs.data?.length) {
                const featureFlagValues = {};
                featureFlags.forEach((flag) => {
                    const featureFlag = clientConfigs.data.find(({ name }) => name === flag);
                    featureFlagValues[flag] = featureFlag ? featureFlag.value === 'true' : false;
                });
                return featureFlagValues;
            }
        } catch (error) {
            console.error('Error fetching client configurations:', error);
            return featureFlags.reduce((acc, flag) => ({ ...acc, [flag]: false }), {});
        }

        return featureFlags.reduce((acc, flag) => ({ ...acc, [flag]: false }), {});
    }
}
