export default {
  component: {
    footer: {
      link: {
        contact: 'お問い合わせ',
        termsAndConditions: '利用規約',
      },
    },
    sideNav: {
      link: {
        recentIssuers: 'Recent issuers',
        activeIssuers: 'Active issuers',
        issuersList: '発行体リスト',
      },
      label: {
        version: 'バージョン:{0}',
      },
    },
    sideNavMenu: {
      expansionItem: {
        transferAgent: '証券代行',
        configuration: '設定',
        'issuer-configuration': '発行体設定',
        advanced: '詳細設定',
        'system-config': 'システム構成',
        'broker-dealer': 'Markets',
        'securitize-id': 'Securitize ID',
      },
      link: {
        createIssuer: 'Create Issuer', // TODO: translate to jp
        issuerList: '発行体リスト',
        dashboard: 'ダッシュボード',
        onboarding: 'オンボーディング',
        fundraise: '資金調達状況',
        holders: '保有者',
        issueDetails: '発行情報詳細',
        signatures: '署名',
        outreach: 'メール送付',
        distributions: '分配',
        snapshots: 'Snapshots',
        securitizeId: 'Investors',
        securitizeIdTranslations: 'Translations',
        securitizeIdWallets: 'Wallets',
        securitizeIdPromotionsMfe: 'Promotions',
        operators: '管理用パネル',
        transferControlBook: '発行数管理',
        transferMSF: '保有者名簿',
        transferDTL: 'トランザクション',
        transferProcedure: 'オペレーション',
        affiliateManagement: '発行体関係者管理',
        generalConfiguration: '一般',
        tokenConfiguration: 'トークン',
        fundraiseConfiguration: '資金調達',
        fundraiseContent: 'Fundraise Content', // TODO: translate to jp
        jurisdictionsPerTokenConfiguration: 'Jurisdictions', // TODO: translate to jp
        jurisdictionsConfiguration: '対象国・免責事項',
        opportunitiesConfiguration: '投資案件',
        assetContent: '案件詳細',
        issuerAffiliates: 'アフィリエイター',
        issuerAgreements: '契約書管理',
        issuerBank: '銀行口座情報',
        issuerConfiguration: '環境設定',
        issuerCurrencies: '受入れ通貨',
        issuerEmailTemplates: 'Eメールテンプレート',
        issuerPlatformDocuments: 'ドキュメント',
        issuerQuestions: 'FAQ',
        issuerTexts: 'テキスト',
        issuerPrerenderedAddresses: '手動アドレス設定',
        systemAgreements: '契約書管理',
        systemQuestions: 'FAQ',
        systemCountries: '国別設定',
        systemEmailTemplates: 'Eメールテンプレート',
        systemTexts: 'システムテキスト',
        clientConfiguration: 'Client Configuration',
        brokerDealerInvestors: 'Investors',
        brokerDealerOpportunities: 'Opportunities',
        brokerDealerSystemConfig: 'System configuration',
        brokerDealerAccreditations: 'Accreditation', // TODO: translate to japanese
        auditLog: 'Audit Log', // TODO: translate to japanese
        'privacy-control': 'Privacy Control', // TODO: translate to japanese
        auditLogIssuer: 'Issuer Audit Log',
        brokerDealerOverview: 'Overview',
        fundraiseMfe: 'Fundraise MFE', // TODO: translate to japanese
        transferAgent: 'Transfer Agent Unified', // TODO: translate to japanese
        transferAgentControlBook: 'Control Book', // TODO: translate to japanese
        transferAgentMSF: 'Master Securityholder File (MSF)', // TODO: translate to japanese
        transferAgentDTL: 'Securities Transactions', // TODO: translate to japanese
        transferAgentProcedure: 'Operational Procedures', // TODO: translate to japanese
        transferAgentAffiliateManagement: 'Affiliate Management', // TODO: translate to japanese
      },
    },
    navbar: {
      input: {
        investorSearch: {
          placeholder: '投資家を探す',
        },
      },
    },
    notifications: {
      label: {
        newNotification: '{0}件の新規お知らせがあります',
        noNotifications: '新規お知らせはありません',
      },
      message: {
        investorsPendingManualReview: '{0}の投資家がKYCの個別レビュー待ちです',
        investorsPendingApproval: '{0}人の投資家がウォレット登録の承認待ちです',
        transactionsRequireSigning: '{0}件のトランザクションが署名待ちです',
        clickHere: '投資家の個別レビューはここをクリック',
      },
    },
    languageSelector: {
      label: {
        en: '英語',
        ja: '日本語',
      },
    },
  },
};
