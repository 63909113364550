export default {
  store: {
    toaster: {
      common: {
        success: 'Request has been sent successfully',
        error: 'An error has occurred while sending the request',
        copyToClipboard: 'Copied to clipboard',
      },
      issuerAffiliates: {
        update: 'Issuer Affiliate is updated',
        delete: 'Issuer Affiliate is deleted',
        create: 'Issuer Affiliate is created',
      },
      issuerAgreements: {
        update: 'Issuer Agreement is updated',
        delete: 'Issuer Agreement is deleted',
        create: 'Issuer Agreement is created',
      },
      bankAccountById: {
        update: 'Issuer Bank account is updated',
        delete: 'Issuer Bank account is deleted',
        create: 'Issuer Bank account is created',
      },
      issuerCurrencies: {
        update: 'Issuer currency is updated',
      },
      issuerEmailTemplates: {
        update: 'Issuer Email template is updated',
        delete: 'Issuer Email template is deleted',
      },
      issuerPlatformDocuments: {
        update: 'Issuer Platform Document is updated',
        delete: 'Issuer Platform Document is deleted',
        create: 'Issuer Platform Document is created',
      },
      issuerPrerenderedAddresses: {
        update: 'Issuer Pre-rendered address is updated',
        delete: 'Issuer Pre-rendered address is deleted',
        create: 'Issuer Pre-rendered address is created',
      },
      issuerQuestion: {
        update: 'Issuer Question is updated',
        delete: 'Issuer Question is deleted',
        create: 'Issuer Question is created',
      },
      issuerTexts: {
        update: 'Issuer Text is updated',
      },
      clientConfiguration: {
        update: 'Client Configuration was updated',
        delete: 'Client Configuration was deleted',
        create: 'Client Configuration was created',
      },
      issuerLabel: {
        deleteSuccess: 'Label {0} deleted',
        successfullyDelete: 'Succesfully deleted',
      },
      fundraise: {
        update: 'Configuration is updated',
      },
      configuration: {
        updateTokenConfiguration: 'Token configuration is updated',
        updateGeneralConfiguration: 'General configuration is updated',
        updateLandingPageConfiguration: 'Landing Page configuration is updated',
        updateEmailConfiguration: 'Email configuration is updated',
        updateInvestorsConfiguration: 'Investors configuration is updated',
      },
      jurisdictions: {
        updateJurisdictions: 'Jurisdictions configuration is updated',
        updateCountry: 'Country is updated',
        updateStateAndRegion: 'State/Region is updated',
        deleteStateAndRegion: 'State/Region is deleted',
        createStateAndRegion: 'State/Region is created',
        importJurisdictionsSettings:
          '{0} jurisdictions have been updated successfully.',
      },
      jurisdictionsPerToken: {
        updateTokenCountry:
          'Country Jurisdiction per asset was updated successfully',
        updateTokenStateAndRegion:
          'State/Region Jurisdiction per asset was updated successfully',
        resetTokenCountry:
          'Country Jurisdiction per asset was reset successfully',
        resetTokenStateAndRegion:
          'State/Region Jurisdiction per asset was reset successfully',
      },
      configToken: {
        updateTokenFundraise: 'Token Fundraise is updated',
        updateTokenLifecycle: 'Token lifecycle management is updated',
        created: 'Token successfully created',
        manageToken: {
          tokenDescriptionAdded: 'Token Description Added',
          tokenDescriptionUpdated: 'Token Description Updated',
          tokenComplianceAdded: 'Token Compliance Added',
          tokenComplianceUpdated: 'Token Compliance Updated',
          mustHaveIssuer: 'Must have at least one issuer',
          mustHaveTransferAgent: 'Must have at least one transfer agent',
          addressExist: 'The same address already exist with a different role',
          tokenPermissionsAdded: 'Token Permissions Added',
          tokenPermissionsUpdated: 'Token Permissions Updated',
          walletAddressMustNotBeMaster:
            'The same wallet address was already declared as master',
          walletAddressMustNotBeWalletSyncer:
            'The same wallet address was already declared as wallet syncer',
          walletAddressMustNotBeOmnibusTBE:
            'The same wallet address was already declared as Omnibus TBE Wallet',
          walletAddressMustNotBeRedemptionAddress:
            'The same wallet address was already declared as Redemption Wallet',
          invalidWalletAddress: 'The Role wallet address is not valid',
          omnibusWalletAddressEqualsToRedemption:
            'The omnibus wallet must be different to redemption wallet',
          masterWalletAddressEqualsToRedemption:
            'The master wallet must be different to redemption wallet',
          masterWalletAddressEqualsToOmnibusTbe:
            'The master wallet must be different to omnibus tbe wallet',
          syncerWalletAddressEqualsToOmnibusTbe:
            'The wallet syncer must be different to omnibus tbe wallet',
          syncerWalletAddressEqualsToRedemption:
            'The wallet syncer must be different to redemption wallet',
          custodianWalletAddressEqualsToMaster:
            'The custodian address must be different to master wallet',
          custodianWalletAddressEqualsToWalletRegistrar:
            'The custodian address must be different to wallet syncer',
          custodianWalletAddressEqualsToRedemption:
            'The custodian address must be different to redemption wallet',
          custodianWalletAddressEqualsToOmnibusTbe:
            'The custodian address must be different to omnibus tbe wallet',
          custodianAddressMustNotBeIssuerOrExchangeAddress:
            'The custodian address must not be an issuer or exchange wallet',
          duplicatedWalletName: 'Wallet name already in use',
          invalidOwnerAddressField:
            'Owner address field on wallet is not valid or missing. Please check for duplicated or invalid ETH address',
          emptyRoles: 'No roles have been defined',
        },
        historicalTokenValue: {
          created: 'Token value successfully added',
          updated: 'Token value successfully updated',
        },
      },
      investors: {
        labelsBatchOperation: 'Labels are updated',
        sendInviteEmail: 'Sent request for invite email',
        sendTreasuryToWallet:
          'Transfer request to {0} saved. Pending signature.',
        createPayout: 'Payout is added',
        remove2Fa: '2Fa is disabled',
        syncSecuritizeIdInvestor: 'Securitize ID Sync was successful.',
        unlinkSecuritizeIdInvestor: 'SecuritizeID Unlink was successful.',
        addInvestor: 'Investor is added',
        deleteInvestorByUserId: 'Investor is deleted',
        importList: 'Investors list is imported or being processed',
        updateInvestorTokensConfirmed: 'Investors details tokens updated',
        updateTokenPreferenceDripConfirmed:
          'Investors token preference has been updated',
        updateInvestorDetailGeneral: 'Investor details general is updated',
        deleteTokenIssuanceById: 'Issuance is deleted',
        createTokenIssuance: 'Token issuance is created',
        editTokenIssuance: 'Token issuance is edited',
        updateInvestorInvestmentById: 'Investor investment is updated',
        createInvestorInvestment: 'Investor investment is created',
        addInvestorDetailDocument: 'Document is added',
        deleteInvestorDetailDocument: 'Document is deleted',
        addInvestorDetailTokenWallet: 'Token wallet is added',
        editInvestorDetailTokenWallet: 'Token wallet is updated',
        deleteInvestorDetailTokenWallet: 'Token wallet is deleted',
        checkInvestorDetailPerformManualKYC:
          'Success! Sent request for external {0}.',
        sendAccreditationEmail: 'Sent request for accreditation email.',
        sendInvestorDetailKYCEmail: 'Success! Sent request for {0} email!',
        updateKYCDetails: '{0} status is updated',
        addInvestorDetailTransaction: 'Transaction is added',
        updateInvestorDetailTransaction: 'Transaction is updated',
        deleteInvestorDetailTransaction: 'Transaction is deleted',
        updateDepositWalletById: 'Deposit wallet is updated',
        moveBonus: 'Token bonus is moved',
        fundingInstructionsEmailSent: 'Funding instructions email was sent',
        agreementEmailSent: 'Agreement emails were sent',
        deleteLegalSigner: 'Legal Signer was deleted',
        addLegalSigner: 'Legal Signer was created',
        bulkSecIDReverseSync: 'Reverse SecID Sync started.',
        bulkRunIssuanceErrorTimeout:
          'Bulk action process not finished - process still runs in the background.',
      },
      issuerList: {
        removeIssuer: 'Issuer is removed',
        createIssuer: 'Issuer is created',
      },
      operators: {
        addOperator: 'Operator is added',
        updateOperator: 'Operator is updated',
        activateOperator: 'Operator is activated',
        deactivateOperator: 'Operator is deactivated',
        resetAuthentication: 'Operator authentication is reset',
      },
      organizations: {
        addOrganization: 'Organization is added',
        updateOrganization: 'Organization is updated',
      },
      rounds: {
        updateRound: 'Round is updated',
        createRound: 'Round is created',
        deleteRound: 'Round is removed',
      },
      signatures: {
        deleteBlockchainTransactionById: 'Signature is deleted',
        sendTransactionSign: 'Transaction is signed',
        bulkSendTransactionSign: 'Bulk Transaction is signed',
        transactionSent: 'Transaction hash sent',
      },
      snapshots: {
        postSnapshotsById: 'Snapshots was created',
        patchSnapshotsById: 'Snapshots name changed',
      },
      snapshotsDistributions: {
        postDistribution: 'Distribution is created',
        confirmDistribution: 'Distribution is confirmed',
      },
      snapshotsRecords: {
        exportSnapshotsRecords: 'Snapshots was Downloaded',
      },
      affiliateManagement: {
        createHolderAffiliateRecords: 'Holder Affiliate Records is set',
      },
      controlBook: {
        updateRecord: 'Record is updated',
        updateSecurities: 'Securities are updated',
      },
      dailyTransactionLog: {
        setTransactionData: 'Transaction is updated',
        addManualTransaction: 'Transaction is added',
        deleteManualTransaction: 'Transaction is deleted',
      },
      procedures: {
        executeOperationalProcedure: 'Blockchain transaction is created',
      },
      multiSigWallet: {
        create: 'Multi-Signature Wallet was created successfully',
        update: 'Multi-Signature Wallet was updated successfully',
        postDeploy: 'Multi-Signature Wallet was sent to deploying process.',
      },
      custodian: {
        custodianUpdated: 'Custodian details updated',
        custodianAdded: 'Custodian was successfully added',
      },
      privacyControl: {
        error: {
          addOperator: 'Operator not found. Please contact support',
        },
        success: {
          addOperator: 'Operator was added',
          deleteOperator: 'Operator was removed',
        },
      },
    },
  },
};
