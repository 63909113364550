import axios from '@/utilities/axios';

const basePath = '/api/v2/fireblocks';

export default class FireblocksService {
    static getAuthorizationHeader = () => {
        const that = this;
        that.configuration = {
            apiKey: '',
            basePath: '/api/v2/fireblocks',
        };

        const localVarHeaderParams = {};
        return {
            ...localVarHeaderParams,
        };
    }

    static getOperatorVaults(operatorId, chainId) {
        const path = `/operators/${operatorId}/vaults?chainId=${chainId}`;
        return this.get(path, { headers: this.getAuthorizationHeader() });
    }

    static signRawTransaction(body) {
        const path = '/transactions/sign';
        return this.post(path, body, { headers: this.getAuthorizationHeader() });
    }

    static get(path, config = {}) {
        return axios.get(basePath + path, config);
    }

    static delete(path, config = {}) {
        return axios.delete(basePath + path, config);
    }

    static post(path, body = {}, config = {}) {
        return axios.post(basePath + path, body, config);
    }

    static put(path, body = {}, config = {}) {
        return axios.put(basePath + path, body, config);
    }
}
