export default {
  investorsOnboarding: {
    title: 'オンボーディング',
    label: {
      run: '投資家{0}名に実行',
      filter: {
        country: '居住国',
        investorType: '個人/法人',
        registrationSource: '登録元',
        hasSecuritizeId: 'SecuritizeID',
        kycStatus: 'KYCステータス',
        accreditedStatus: '適格性ステータス',
        createdAt: '作成日',
        walletStatus: 'ウォレットステータス',
      },
      table: {
        investorName: '投資家',
        country: '居住国',
        investorType: 'タイプ',
        registrationSource: '登録元',
        hasSecuritizeId: 'SecuritizeID',
        kycStatus: 'KYC\nステータス',
        riskLevel: 'Risk Level', // TODO: translate to jp
        accreditedStatus: '適格性\nステータス',
        labels: 'ラベル',
        createdAt: '作成日',
      },
    },
    status: {
      kyc: {
        none: 'なし',
        processing: '処理中',
        manualReview: '個別レビュー',
        updatesRequired: 'Updates Required', // TODO: translate to jp
        verified: 'Verified',
        enhancedVerified: 'enhanced verified', // TODO: translate to jp
        rejected: '却下',
        expired: 'expired',
      },
      accredited: {
        none: 'なし',
        pending: '保留中',
        verified: 'verified', // TODO: translate to jp
        no: '非適格',
        expired: 'expired', // TODO: translate to jp
        updatesRequired: 'updates required', // TODO: translate to jp
        processing: 'Processing', // TODO: translate to jp
        notAccredited: 'Not Accredited', // TODO: translate to jp
        rejected: '却下',
      },
      hasSecuritizeId: {
        none: 'なし',
        exists: 'Exists',
      },
    },
    loading: '投資家データを読込中...',
    option: {
      operations: {
        inviteEmail: '招待メール',
        passwordReset: 'パスワードリセット',
        kycEmail: 'KYCメール',
        accreditationEmail: '適格性メール',
        inviteSecidEmail: 'Invite SecID Email',
        secIDReverseSync: 'Reverse SecID Sync', // TODO: translate to jp
      },
      registrationSource: {
        internal: '内部',
      },
      kycStatus: {
        none: 'なし',
        processing: '処理中',
        manualReview: '個別レビュー',
        updatesRequired: 'Updates Required', // TODO: translate to jp
        verified: 'Verified',
        enhancedVerified: 'Enhanced Verified', // TODO: translate to jp
        rejected: '却下',
        expired: 'Expired',
      },
      accreditedStatusList: {
        no: '非適格',
        noAccepted: '非適格だが投資可能',
        pending: '保留中',
        confirmed: '確認済み',
        rejected: '却下',
        none: 'なし',
        notAccredited: 'Not Accredited', // TODO: translate to jp
        processing: 'Processing', // TODO: translate to jp
      },
      walletStatus: {
        pending: '保留中',
        syncing: '同期中',
        ready: '準備完了',
        rejected: '不合格',
        cancelled: '取消済',
      },
      hasSecuritizeIdList: {
        none: 'None',
        exists: 'Exists',
        all: 'All',
      },
    },
  },
  deleteLabelConfirmModal: {
    title: 'Delete label',
    messagePartOne: 'Please confirm that you wish to delete the label',
    messagePartTwo: 'Any existing investor with this label will be affected.',
  },
};
