import { getArgumentsForApi, tokensApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';
import {
    getAbstractionLayerMultisigWalletsByDeploymentId,
    getAbstractionLayerMultisigWalletsByDeploymentIdAndWalletId,
    getAbstractionLayerMultisigWalletTransactionsStatus,
    getAbstractionLayerMultisigWalletTransactionsStatusList,
    getAbstractionLayerNetworkInfo,
    getAbstractionLayerTokenProvider,
    getOperatorHSMKeys,
    getOperatorMessageSignature,
    getOperatorTransactionSignature,
} from '@/utilities/external-api/apis/abstractionLayerCpApi';
import SignaturesService from '~/utilities/signaturesService';
import FireblocksService from '~/utilities/fireblocks';
import { fromGWeiToWei, fromWeiToGWei } from '../configuration/token/constants';
import IssuerClientConfigurations from '../../../pages/_idIssuer/signatures/issuerClientConfigurations';

const { getTokenById } = tokensApi;

const transactionArguments = {
    issuerId: undefined,
    tokenId: undefined,
    page: undefined,
    limit: undefined,
    orderDirection: undefined,
    orderField: undefined,
    status: undefined,
};

const getSignaturesAndThreshold = async (deploymentId, transactions) => {
    try {
        const transactionIds = transactions.map(item => item.id).join(',');

        const multiSigTransactionsResponse = deploymentId ? await getAbstractionLayerMultisigWalletTransactionsStatusList(deploymentId,
            transactionIds,
            false) : undefined;
        return transactions.map((transaction) => {
            let signatures = 0;
            let threshold = 1;
            if (multiSigTransactionsResponse) {
                const multiSigTransactionWithSignature = multiSigTransactionsResponse.data.filter(multiSigTransaction => multiSigTransaction.clientTransactionId === transaction.id.toString());
                if (multiSigTransactionWithSignature?.length) {
                    // eslint-disable-next-line prefer-destructuring
                    signatures = multiSigTransactionWithSignature[0].signatures;
                    // eslint-disable-next-line prefer-destructuring
                    threshold = multiSigTransactionWithSignature[0].threshold;
                }
            }
            return {
                ...transaction,
                threshold,
                signatures,
            };
        });
    } catch (err) {
        this.$log.error('Get multi-sig transaction status error:', err);
    }
    return transactions.map(transaction => ({
        ...transaction,
        threshold: 1,
        signatures: 0,
    }));
};

const getTransactionsWithSignatureCount = async (deploymentId, transactions) => getSignaturesAndThreshold(deploymentId, transactions);

export default {
    async getOperatorVaults(ctx, { operatorId, chainId }) {
        try {
            return FireblocksService.getOperatorVaults(operatorId, chainId);
        } catch (err) {
            this.$log.error('Get operator vaults error:', err);
            throw err;
        }
    },

    async signRawTransaction(ctx, { vaultAccountId, chainId, rawTransaction }) {
        try {
            return FireblocksService.signRawTransaction({ vaultAccountId, chainId, rawTransaction });
        } catch (err) {
            this.$log.error('Sign raw transaction error:', err);
            throw err;
        }
    },

    async deleteTransactionSignatureById({ commit }, { id, issuerId, tokenId }) {
        try {
            await SignaturesService.deleteTransactionSignatureById(issuerId, tokenId, id);
            commit('global/CALL_TOASTER', i18n.t('store.toaster.signatures.deleteBlockchainTransactionById'), { root: true });
        } catch (err) {
            this.$log.error('Delete signature by id error:', err);
        }
    },

    async clearTransactionSignaturesById({ commit }, { id, issuerId, tokenId }) {
        try {
            await SignaturesService.clearTransactionSignaturesById(issuerId, tokenId, id);
            commit('global/CALL_TOASTER', i18n.t('store.toaster.signatures.deleteBlockchainTransactionById'), { root: true });
        } catch (err) {
            this.$log.error('Clear signature by id error:', err);
        }
    },

    getTokenConfiguration(ctx, { issuerId, tokenId }) {
        return getTokenById(issuerId, tokenId)
            .then(data => data)
            .catch((err) => {
                this.$log.error('Get Token Configuration Error:', err);
                throw err;
            });
    },

    async invokeDsProtocol(ctx, { issuerId, tokenId, blockchainTransactionId, signerAddress }) {
        try {
            const res = await SignaturesService.invokeDsProtocol(issuerId, tokenId, blockchainTransactionId, signerAddress);
            return res;
        } catch (err) {
            this.$log.error('Invoking DS Protocol transaction error', err);
            throw err;
        }
    },

    async bulkDsProtocolTransactions(ctx, { issuerId, tokenId, signerAddress, transactionsList }) {
        try {
            const res = await SignaturesService.bulkDsProtocolTransactions(issuerId, tokenId, signerAddress, transactionsList);
            return res;
        } catch (err) {
            this.$log.error('Invoking Bulk DS Protocol transaction error', err);
            throw err;
        }
    },

    async getClientConfigurations(ctx, { issuerId, featureFlags }) {
        try {
            return IssuerClientConfigurations.getClientConfigurations(issuerId, featureFlags);
        } catch (err) {
            this.$log.error('Checking Issuer client feature flags', err);
            throw err;
        }
    },

    async sendTransactionSignature({ commit }, { issuerId, tokenId, blockchainTransactionId, body }) {
        try {
            await SignaturesService.sendTransactionSignature(issuerId, tokenId, blockchainTransactionId, body);
            commit('global/CALL_TOASTER', i18n.t('store.toaster.signatures.sendTransactionSign'), { root: true });
        } catch (err) {
            this.$log.error('Sending transaction signature error', err);
            throw err;
        }
    },

    async sendBulkDsProtocolTransactionSignature({ commit }, { issuerId, tokenId, body }) {
        try {
            await SignaturesService.sendBulkDsProtocolTransactionSignature(issuerId, tokenId, body);
            commit('global/CALL_TOASTER', i18n.t('store.toaster.signatures.bulkSendTransactionSign'), { root: true });
        } catch (err) {
            this.$log.error('Sending Bulk DSProtocol transaction signature error', err);
            throw err;
        }
    },

    async sendMultiSigLastTransactionSign(ctx, { issuerId, tokenId, blockchainTransactionId, body }) {
        return SignaturesService.sendTransactionSignature(issuerId, tokenId, blockchainTransactionId, body);
    },

    async getTransactionSignatureById(ctx, { id, issuerId, tokenId }) {
        try {
            const data = await SignaturesService.getTransactionSignatureById(issuerId, tokenId, id);
            return data;
        } catch (err) {
            this.$log.error('Get signature by id error:', err);
        }
    },

    async getTransactionSignaturesList(ctx, { params }) {
        const [issuerId, tokenId, ...args] = getArgumentsForApi(transactionArguments, params);
        const page = args[0] || 0;
        const limit = args[1] || 25;
        const status = args[4] || 'all';

        try {
            const data = await SignaturesService.getTransactionSignaturesList(issuerId, tokenId, { page, limit, status });
            if (data?.data?.data?.length) {
                const { deploymentId } = data.data.data[0];
                const statusData = await getTransactionsWithSignatureCount(deploymentId, data.data.data);
                data.data.data = statusData;
            }

            return data;
        } catch (error) {
            throw error;
        }
    },

    getMultisigWalletsByDeploymentId(ctx, { deploymentId }) {
        return getAbstractionLayerMultisigWalletsByDeploymentId(deploymentId);
    },

    getMultisigWalletsByDeploymentIdAndWalletId(ctx, { deploymentId, walletId }) {
        return getAbstractionLayerMultisigWalletsByDeploymentIdAndWalletId(deploymentId, walletId);
    },

    getMultisigWalletTransactionsStatus(ctx, { deploymentId, clientTransactionId, includeTransactionData }) {
        return getAbstractionLayerMultisigWalletTransactionsStatus(deploymentId, clientTransactionId, includeTransactionData);
    },

    getAbstractionLayerNetworkInfo(ctx, { providerName }) {
        return getAbstractionLayerNetworkInfo(providerName);
    },

    getAbstractionLayerTokenProvider(ctx, { tokenId }) {
        return getAbstractionLayerTokenProvider(tokenId);
    },

    getOperatorHSMKeys(ctx, { operatorId }) {
        return getOperatorHSMKeys(operatorId);
    },

    getOperatorTransactionSignature(ctx, { hsmAddress, unsignedTx, options }) {
        return getOperatorTransactionSignature(hsmAddress, unsignedTx, options);
    },

    getOperatorMessageSignature(ctx, { hsmAddress, unsignedMessage }) {
        return getOperatorMessageSignature(hsmAddress, unsignedMessage);
    },

    async prepareTransactionForSpeedUp(ctx, { issuerId, tokenId, blockchainTransactionId, signerAddress, gasPrice }) {
        try {
            const gasPriceWei = fromGWeiToWei(gasPrice);
            const res = await SignaturesService.speedUpTransaction(
                issuerId,
                tokenId,
                blockchainTransactionId,
                signerAddress,
                gasPriceWei.toString(),
            );
            return res;
        } catch (err) {
            this.$log.error('SpeedUp transaction error', err);
            throw err;
        }
    },

    async prepareTransactionForCancel(ctx, { issuerId, tokenId, blockchainTransactionId, signerAddress }) {
        try {
            const res = await SignaturesService.cancelTransaction(
                issuerId,
                tokenId,
                blockchainTransactionId,
                signerAddress,
            );
            return res;
        } catch (err) {
            this.$log.error('Cancel transaction error', err);
            throw err;
        }
    },

    async getProviderTransactionDetail(ctx, { issuerId, tokenId, id }) {
        try {
            const providerTransactionDetail = await SignaturesService.getProviderTransactionDetail(issuerId, tokenId, id);
            const gasPriceWei = providerTransactionDetail.gasPrice;
            const gasPriceGWei = fromWeiToGWei(gasPriceWei);
            return {
                gasPrice: gasPriceGWei,
                signerAddress: providerTransactionDetail.signerAddress,
            };
        } catch (err) {
            this.$log.error('Get provider transaction detail error:', err);
        }
    },

    async getDeploymentGasPrice(ctx, { issuerId, tokenId }) {
        try {
            const data = await SignaturesService.getDeploymentGasPrice(issuerId, tokenId);
            return data;
        } catch (err) {
            this.$log.error('Get deployment gas-price transaction error:', err);
        }
    },
};
