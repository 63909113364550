import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';
import globalStore from '@/store/modules/global';
import issuerStore from '@/store/modules/issuer-list';

export default async () => {
  const {
    state: {
      id,
      idUUID,
      name,
      email,
      authorizationLevel,
      externalId,
      authorizations,
      picture,
    },
  } = currentOperatorStore;

  return Frame(
    'reporting',
    'Fundraise',
    './ReportingApp',
    process.env.VUE_APP_REPORTING_MFE_ENTRY_POINT,
    () => {
      const {
        state: { idUUID: operatorId },
      } = currentOperatorStore;
      const {
        state: { locale },
      } = globalStore;
      const {
        state: { current },
      } = issuerStore;

      return {
        operatorId,
        locale,
        issuerId: current.id,
        operatorInfo: {
          operatorId: id,
          idUUID,
          name,
          email,
          authorizationLevel,
          externalId,
          authorizations,
          picture,
        },
      };
    },
  );
};
