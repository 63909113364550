export default {
  component: {
    footer: {
      link: {
        contact: 'Contact',
        termsAndConditions: 'Terms & Conditions',
      },
    },
    sideNav: {
      link: {
        recentIssuers: 'Recent issuers',
        activeIssuers: 'Active issuers',
        issuersList: 'Issuers List',
      },
      label: {
        version: 'version:{0}',
      },
    },
    sideNavMenu: {
      expansionItem: {
        transferAgent: 'Transfer Agent',
        configuration: 'Configuration',
        'issuer-configuration': 'Issuer Configuration',
        advanced: 'Advanced',
        'system-config': 'System Config',
        'broker-dealer': 'Markets',
        'securitize-id': 'Securitize ID',
      },
      link: {
        createIssuer: 'Create Issuer',
        issuerList: 'Issuers',
        dashboard: 'Dashboard',
        onboarding: 'Onboarding',
        fundraise: 'Fundraise',
        holders: 'Holders',
        issueDetails: 'Issue Detail',
        signatures: 'Signatures',
        outreach: 'Investor Engagement',
        distributions: 'Distributions',
        snapshots: 'Snapshots',
        securitizeId: 'Investors',
        securitizeIdMfe: 'Investors MFE',
        securitizeIdTranslations: 'Translations',
        securitizeIdTranslationsMfe: 'Translations MFE',
        securitizeIdWallets: 'Wallets',
        securitizeIdWalletsMfe: 'Wallets MFE',
        securitizeIdPromotionsMfe: 'Promotions',
        operators: 'Panel Administration',
        transferControlBook: 'Control Book',
        transferMSF: 'Master Securityholder File (MSF)',
        transferDTL: 'Securities Transactions',
        transferProcedure: 'Operational Procedures',
        affiliateManagement: 'Affiliate Management',
        generalConfiguration: 'General',
        tokenConfiguration: 'Token',
        fundraiseConfiguration: 'Fundraise',
        fundraiseContent: 'Fundraise Content',
        jurisdictionsPerTokenConfiguration: 'Jurisdictions',
        jurisdictionsConfiguration: 'Jurisdictions',
        opportunitiesConfiguration: 'Opportunities',
        assetContent: 'Asset Content',
        issuerAffiliates: 'Issuer Affiliates',
        issuerAgreements: 'Issuer Agreements',
        issuerBank: 'Issuer Bank Accounts',
        issuerConfiguration: 'Issuer Configuration Variables',
        issuerCurrencies: 'Issuer Currencies',
        issuerEmailTemplates: 'Issuer Email Templates',
        issuerPlatformDocuments: 'Issuer Platform Documents',
        issuerQuestions: 'Issuer Questions',
        issuerTexts: 'Issuer Texts',
        issuerPrerenderedAddresses: 'Issuer Prerendered addresses',
        systemAgreements: 'Agreements',
        systemQuestions: 'Questions',
        systemCountries: 'Countries',
        systemEmailTemplates: 'Email Templates',
        systemTexts: 'System Texts',
        clientConfiguration: 'Client Configuration',
        brokerDealerInvestors: 'Investors',
        brokerDealerOverview: 'Overview',
        brokerDealerOpportunities: 'Opportunities',
        brokerDealerSystemConfig: 'System configuration',
        brokerDealerAccreditations: 'Accreditation',
        auditLog: 'Audit Log',
        privacyControl: 'Privacy Control',
        auditLogIssuer: 'Issuer Audit Log',
        fundraiseMfe: 'Fundraise MFE',
        transferAgentControlBook: 'Control Book',
        transferAgentMSF: 'Master Securityholder File (MSF)',
        transferAgentDTL: 'Securities Transactions',
        transferAgentProcedure: 'Operational Procedures',
        transferAgentAffiliateManagement: 'Affiliate Management',
      },
    },
    navbar: {
      input: {
        investorSearch: {
          placeholder: 'Go to investor',
        },
      },
    },
    notifications: {
      label: {
        newNotification: '{0} new notification(s)',
        noNotifications: 'No notifications',
      },
      message: {
        investorsPendingManualReview:
          '{0} Investors are pending manual review for KYC',
        investorsPendingApproval:
          '{0} Investors are pending approval to register a wallet',
        transactionsRequireSigning: '{0} Transactions(s) require signing',
        clickHere: 'Click here to manually review the investors.',
      },
    },
    languageSelector: {
      label: {
        en: 'English',
        ja: 'Japanese',
      },
    },
  },
};
