export default {
  opportunities: {
    title: 'Create and manage fundraises',
    opportunitiesList: {
      noOpportunities: {
        title: 'You do not have any opportunities yet',
        content: 'Opportunities are a content management system you can use to provide your investors with information about your investment offerings',
        addButton: {
          label: 'Create new opportunity',
        },
      },
      addButton: {
        label: 'Create opportunity',
      },
      deleteButton: {
        label: 'Delete',
      },
      detailButton: {
        label: 'View details',
      },
      publishButton: {
        label: 'Publish',
      },
      unPublishButton: {
        label: 'Unpublish',
      },
      draftText: 'Draft',
      publishedText: 'Published',
      tokenName: 'Token name',
      roundName: 'Round name',
      deleteConfirmation: {
        title: 'Do you want to delete',
        content: 'This action cannot be undone',
        deleteButton: 'Delete',
      },
      publishConfirmation: {
        title: 'Do you want to publish',
        content: 'As soon as it is published, your investors will have access to it. You will be able to unpublish it if you want later.',
        publishButton: 'Publish',
      },
      unPublishConfirmation: {
        title: 'Do you want to unpublish the opportunity',
        content: 'If you unpublish it, visitors to your website will no longer see it. You can publish it again later.',
        unPublishButton: 'Unpublish',
      },
    },
    addOpportunity: {
      windowTitle: 'Create Opportunity',
      title: 'Provide some information about your Opportunity',
      createOpportunityButtonText: 'Create opportunity',
      cancelButtonText: 'Cancel',
      tokenSelect: {
        label: 'Token',
      },
      roundSelect: {
        label: 'Round',
      },
      titleText: {
        label: 'Opportunity name',
        placeholder: 'e.g. Opportunity 2020',
      },
    },
    editOpportunity: {
      tabs: {
        summaryTab: 'Summary',
        contentTab: 'Content',
        documentsTab: 'Documents',
      },
      summary: {
        header: {
          text: 'Opportunity summary',
        },
        publishedText: 'Published',
        unpublishedText: 'Unpublished',
        shadowOn: 'Shadow Mode On',
        shadowOff: 'Shadow Mode Off',
        qualifiedOnlyOn: 'Qualified purchasers On',
        qualifiedOnlyOff: 'Qualified purchasers Off',
        accreditedOnlyOn: 'Accredited investors On',
        accreditedOnlyOff: 'Accredited investors Off',
        signedDate: 'Signed Date',
        token: {
          label: 'Token',
        },
        round: {
          label: 'Round',
        },
        image: {
          label: 'Image (Opportunity Details)',
        },
        smallImage: {
          label: 'Small image (Opportunity List)',
        },
        title: {
          label: 'Opportunity name',
        },
        description: {
          label: 'Description (Opportunity Details)',
        },
        shortDescription: {
          label: 'Short description (Opportunity List)',
        },
        scheduleCallLink: {
          label: 'Schedule a call',
        },
        order: {
          label: 'Order',
        },
        attributes: {
          attribute1: {
            label: 'Attribute name',
            valueLabel: 'Value',
          },
          attribute2: {
            label: 'Attribute name',
            valueLabel: 'Value',
          },
        },
        fields: {
          annualizedYield: {
            label: 'Annualized Yield',
          },
          term: {
            label: 'Term',
          },
        },
      },
      content: {
        visibility: {
          whoTitle: 'Visibility (who)',
          whereTitle: 'Visibility (where)',
          showToVerifiedInvestorsOnly: 'Verified',
          showInTokenDetails: 'Asset content',
          showInOpportunity: 'Opportunity',
        },
        options: {
          kycRequiredText: 'KYC required to see Contents',
          noKycRequiredText: 'KYC not required to see Contents',
          headerText: 'Options',
        },
        add: {
          button: {
            text: 'Add content box',
          },
          modal: {
            title: {
              label: 'What type of content box do you want to add?',
            },
            buttons: {
              video: {
                title: 'Video box',
                description: 'This is a video associated to your token info',
              },
              text: {
                title: 'Text box',
                description: 'Description about your opportunity',
              },
              imgWithDesc: {
                title: 'Image box',
                description: 'Image with description about your opportunity',
              },
              telegram: {
                title: 'Telegram Widget Box',
                description: 'Telegram widget for displaying channels or post related to your opportunity',
              },
              side: {
                options: {
                  left: 'Left Column',
                  right: 'Right Column',
                },
                infoRightMsg: 'One publication of an image and one publication of a text may be made.',
              },
              documents: {
                title: 'Documents',
                description: 'Documents associated with your opportunity',
              },
              progress: {
                title: 'Investments in Progress',
                description: 'List of investments in progress',
              },
            },
          },
        },
        videoBox: {
          title: 'Video content',
          play: {
            label: 'Play',
          },
          inputs: {
            title: {
              label: 'Video title',
              placeholder: 'e.g. Informative Video',
            },
            video: {
              label: 'Youtube/ Vimeo link',
              placeholder: 'e.g. https://youtu.be/5qap5aO4i9A',
              error: 'The video url field is required',
            },
            description: {
              label: 'Content',
              placeholder: 'e.g. This video will show you all you need to know',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
          },
        },
        textBox: {
          title: 'Text content',
          inputs: {
            title: {
              label: 'Title',
              placeholder: 'e.g. Summary about us',
            },
            description: {
              label: 'Content',
              placeholder: 'e.g. e.g. This content will show you all you need to know',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
            sideType: {
              label: 'Side',
              options: {
                left: 'Left',
                right: 'Right',
              },
            },
          },
        },
        imageBox: {
          title: 'Image content',
          inputs: {
            description: {
              label: 'Description',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
            image: {
              label: 'Image',
            },
            alignment: 'Align image to text',
          },
        },
        telegramBox: {
          title: 'Telegram Widget',
          inputs: {
            title: {
              label: 'Title',
              placeholder: 'e.g. Summary about us',
            },
            widgetType: {
              label: 'Type',
              options: {
                discussion: 'Discussion',
                post: 'Post',
              },
            },
            widgetResourceId: {
              label: 'Resource Id',
              placeholder: 'e.g telegram/83',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
          },
        },
        documentsBox: {
          title: 'Documents',
          inputs: {
            title: {
              label: 'Title',
              placeholder: 'e.g. Summary about us',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
          },
        },
        progressBox: {
          title: 'Investments in Progress',
          inputs: {
            title: {
              label: 'Title',
              placeholder: 'e.g. Summary about us',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
          },
        },
        newText: 'New',
        deleteConfirmation: {
          title: 'Do you want to delete',
          content: 'This action cannot be undone',
          deleteButton: 'Delete',
        },
        language: {
          label: 'Language',
          publishText: 'Publish',
        },
      },
      document: {
        edit: {
          header: 'Document details',
          changeFile: {
            label: 'Change File',
          },
          title: {
            label: 'Document name',
            placeholder: 'e.g. Articles of Incorporation',
          },
          restrictions: {
            label: 'Download restrictions',
            kycOrKyb: 'Require KYC/KYB',
            qualification: 'Require qualification',
          },
          order: {
            label: 'Order',
          },
          addButton: {
            label: 'Add document',
          },
          saveButton: {
            label: 'Save changes',
            savingText: 'Saving...',
          },
        },
        list: {
          addButton: {
            label: 'Add document',
          },
          downloadButton: {
            label: 'Download',
          },
          table: {
            th: {
              title: 'Name',
              image: 'Thumbnail',
              requireKyc: 'Require KYC/KYB',
              requireQualification: 'Require qualification',
              createdAt: 'Creation Date',
              actions: '',
            },
          },
          deleteConfirmation: {
            title: 'Do you want to delete',
            content: 'This action cannot be undone',
            deleteButton: 'Delete',
          },
        },
      },
    },
  },
};
