export default {
  common: {
    companyInfo: {
      name: 'Securitize',
    },
    blockchain: {
      ethereum: 'Ethereum',
      tezos: 'Tezos',
      algorand: 'Algorand',
    },
    label: {
      all: 'All',
      yes: 'Yes',
      no: 'No',
      none: 'none',
      na: 'N\\A',
      active: 'Active',
      inactive: 'Inactive',
    },
    button: {
      ok: 'OK',
      cancel: 'Cancel',
      add: 'Add',
      reset: 'Reset',
      edit: 'Edit',
      save: 'Save',
      saveChanges: 'Save changes',
      change: 'Change',
      send: 'Send',
      close: 'Close',
      delete: 'Delete',
      activate: 'Activate',
      deactivate: 'Deactivate',
      activateAll: 'Activate All',
      deactivateAll: 'Deactivate All',
      view: 'View',
      confirm: 'Confirm',
      yes: 'Yes',
      no: 'No',
    },
    placeholder: {
      filter: 'Filter',
      search: 'Search...',
    },
    category: {
      authorizationLevel: {
        basic: 'Basic',
        admin: 'Admin',
        superadmin: 'Super Admin',
        megaadmin: 'Mega Admin',
        brokerdealer: 'Broker Dealer',
      },
      investorType: {
        entity: 'Entity',
        individual: 'Individual',
        omnibus: 'Omnibus',
        custodian: 'Custodian',
        fboIndividual: 'FBO Individual',
        fboEntity: 'FBO Entity',
        none: 'None',
      },
      entityType: {
        revocableTrust: 'Revocable Trust',
        irrevocableTrust: 'Irrevocable Trust',
        limitedGeneralPartnership: 'Limited Partnership/General Partnership',
        fund: 'Fund',
        llc: 'LLC',
        corporation: 'Corporation',
        jointAccount: 'Joint Account',
        dao: 'DAO',
        forProfitFoundation: 'For-Profit Foundation',
        nonProfitFoundation: 'Non-Profit Foundation',
        other: 'Other',
      },
    },
  },
};
