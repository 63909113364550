export default {
  investorsOnboarding: {
    title: 'Onboarding',
    label: {
      run: 'Run for {0} investors',
      filter: {
        country: 'Country',
        investorType: 'Individual/Entity',
        registrationSource: 'Origin',
        hasSecuritizeId: 'SecuritizeID',
        kycStatus: 'KYC Status',
        accreditedStatus: 'Accreditation Status',
        createdAt: 'Date created/added',
        walletStatus: 'Wallet Status',
      },
      table: {
        investorName: 'Name / Entity',
        country: 'Country',
        investorType: 'Type',
        registrationSource: 'Origin',
        hasSecuritizeId: 'SecuritizeID',
        kycStatus: 'KYC Status',
        riskLevel: 'Risk Level',
        accreditedStatus: 'Accredited Status',
        labels: 'Labels',
        createdAt: 'Created',
      },
    },
    status: {
      kyc: {
        none: 'none',
        processing: 'processing',
        manualReview: 'manual review',
        updatesRequired: 'updates required',
        verified: 'verified',
        enhancedVerified: 'enhanced verified',
        rejected: 'rejected',
        expired: 'expired',
      },
      accredited: {
        none: 'none',
        pending: 'pending',
        verified: 'verified',
        no: 'no',
        expired: 'expired',
        updatesRequired: 'updates required',
        processing: 'processing',
        notAccredited: 'not-accredited',
        rejected: 'rejected',
      },
      hasSecuritizeId: {
        none: 'None',
        exists: 'Exists',
      },
    },
    loading: 'Loading Investor Data...',
    option: {
      operations: {
        inviteEmail: 'Invite Email',
        passwordReset: 'Password Reset',
        kycEmail: 'KYC Email',
        accreditationEmail: 'Accreditation Email',
        inviteSecidEmail: 'Invite SecID Email',
        secIDReverseSync: 'Reverse SecID Sync',
      },
      registrationSource: {
        internal: 'Internal',
      },
      kycStatus: {
        none: 'None',
        processing: 'Processing',
        manualReview: 'Manual Review',
        updatesRequired: 'Updates Required',
        verified: 'Verified',
        enhancedVerified: 'Enhanced Verified',
        rejected: 'Rejected',
        expired: 'Expired',
      },
      accreditedStatusList: {
        no: 'No',
        noAccepted: 'No Accepted',
        pending: 'Pending',
        confirmed: 'Confirmed',
        rejected: 'Rejected',
        none: 'None',
        notAccredited: 'Not Accredited',
        processing: 'Processing',
      },
      walletStatus: {
        pending: 'Pending',
        syncing: 'Syncing',
        ready: 'Ready',
        rejected: 'Rejected',
        cancelled: 'Cancelled',
      },
      hasSecuritizeIdList: {
        none: 'None',
        exists: 'Exists',
        all: 'All',
      },
    },
  },
  deleteLabelConfirmModal: {
    title: 'Delete label',
    messagePartOne: 'Please confirm that you wish to delete the label',
    messagePartTwo: 'Any existing investor with this label will be affected.',
  },
};
